<template>
   <div class="mubiao">
        <div class="top">
            <div>
             <template>
              <el-radio v-model="data_top" label="1">早午晚市划分</el-radio>
              <el-radio v-model="data_top" label="2">店铺售卖规则</el-radio>
            </template>
            </div>
        </div>
        <div class="main" v-if="data_top === '1' ">
            <div class="left">
              <ul>
                <li v-for="(item,index) in data_side" :key="index" :class="{'active':market_type.sum===item.sum}"><p @click="query_as_market_prod_list(item)">{{item.label}}</p></li>
              </ul>
            </div>
            <div class="right">
                <div class="content">
                    <div class="right">
                      <el-card style="height:100%;padding:20px;  box-sizing: border-box;">
                        <el-scrollbar style="height:420px;">
                             <c-menu  v-model="datas.data_cai_value" @options_value="handleCheckedCitiesChange"></c-menu>
                         </el-scrollbar>
                      </el-card>
                    </div>
                    <div class="left">
                      <el-card class="box-card" style="height:100%">
                        <el-scrollbar style="height:420px;">
                        <ul v-for="(item,index) in datas.data_list" :key="index" :class="{'active':index%2 === 0}">
                          <li>
                            <div class="img"><img :src="item.prod_pic" v-if="item.prod_pic" alt=""><p style="font-size:12px;" v-else>无图</p></div>
                            <p style="margin-left:20px;">{{item.prod_name}}</p>
                            <c-icon name="guanbi" style="font-size:20px;" @click.native="del_left_list(index,item.prod_id)"></c-icon>
                          </li>
                        </ul>
                        </el-scrollbar>
                      </el-card>
                    </div>
                </div>
                <div style="text-align:center">
                  <el-button @click="save_canpin" type="danger">保存</el-button>
                </div>
                <p class="title_ti">提示：<早午晚市划分> 与 <店铺售卖规则>都为必填项 ！</p>
            </div>
        </div>
        <div class="main two" v-else>
          <div class="save"><el-button @click="submit()"  type="danger">保存</el-button></div>
          <div class="box" v-for="(item,index) in data_list" :key="index">
            <div class="title"><p>{{item.title}}</p> <!-- <c-icon name="guanbi" @click.native="del_fangan(data_list,index,item)"></c-icon> --> </div>
            <div class="box1">
              <ul>
                <el-scrollbar style="height:200px">
                   <li v-for="(ss,indexx) in item.mendian" :key="indexx"><span></span><p>{{ss.shop_name}}</p>
                   <!-- <p @click="del_mendian(item.mendian,ss,indexx)">删除</p> -->
                   </li>
                </el-scrollbar>
                <div class="div-button"><el-button @click="add(item.mendian,index,0)" size="mini" type="danger">新增门店</el-button></div>
              </ul>
              <div class="sidbar" :class="{'active': index === index_mun}" @mouseleave="index_mun = null">
                <div class="title"><p>请选择</p></div>
                <div class="main" >
                   <!-- 添加门店 -->
                    <ul v-if="!is_mendian_or_caipin" >
                       <el-scrollbar style="height:100%">
                            <!-- <el-checkbox-group v-model="options_value_box" style="width:100px;" @change="changeItem">
                              <el-checkbox v-for="city in options" :label="city.shop_id" :key="city.shop_id">{{city.shop_name}}</el-checkbox>
                            </el-checkbox-group> -->
                            <div class="mybox">
                              <li v-for="(i,indexi) in options" :key="indexi" :class="{'end_active':JSON.stringify(options_value_box).includes(i.shop_id)}" @click="changeItem(i,indexi,options,item)"><p>{{i.shop_name}}</p><c-icon name="duigou"></c-icon></li>
                            </div>
                          </el-scrollbar>

                        <div class="tijiao">
                          <!-- <el-button  size="mini" @click="push_mendian(item.mendian)"  type="danger">确定</el-button> -->
                        </div>
                    </ul>
                    <!-- 添加产品 -->
                     <ul v-else>
                         <el-scrollbar style="height:420px">
                            <!-- <el-checkbox-group v-model="options_value" style="width:100px;">
                              <el-checkbox v-for="city in data_cai_list" :label="city.prod_id" :key="city.prod_id">{{city.prod_name}}</el-checkbox>
                            </el-checkbox-group> -->
                                <c-menu @options_value="message_options_value" v-if="is_mendian_or_caipin"></c-menu>
                          </el-scrollbar>
                        <div class="tijiao">
                          <el-button  size="mini" @click="push_caipin(item.datacaipin,item)"  type="danger">确定</el-button>
                        </div>
                    </ul>
                </div>
              </div>
            </div>
            <div class="box1 box2">
              <ul>
                 <el-scrollbar style="height:200px">
                   <!-- <li v-for="(x,inde) in item.datacaipin" :key="inde"><span></span><p>{{x.prod_name}}</p><p  @click="item.datacaipin.splice(inde,1)">删除</p></li> -->
                  <!--  <c-menuList v-model="item.datacaipin"></c-menuList> -->
                     <c-menuList v-model="item.datacaipin"></c-menuList>
                 </el-scrollbar>
                <div class="div-button"><el-button @click="add(item.datacaipin,index,1)" size="mini" type="danger" >新增菜品</el-button></div>
              </ul>
            </div>
          </div>
          <div class="box box3" @click="add_fangan" v-if="data_list.length < 1">
             <div class="box3" ><c-icon name="xinzeng" style="fill:red; font-size:38px;"></c-icon><p style="color:red" >新增</p></div>

          </div>

        </div>


   </div>
</template>

<script>
const data_side = [
  { label: '早市', sum: '1' },
  { label: '午市', sum: '2' },
  { label: '晚市', sum: '3' },
  { label: '外卖', sum: '4' }
]
import { query_shop_info_list, query_prod_info_list, insert_as_shop_prod_rule,query_as_shop_prod_list } from '@/api/store'
import { query_category_info_list, query_as_market_prod_list,insert_as_market_prod ,query_as_prod_rule,query_prod_tree_of_1cat_2prod} from '@/api/foodqiye'
import { query_all_prod_list_of_category,query_category_tree } from '@api/public'
import { mapActions} from 'vuex'
export default {
  name: "Mubiao",
  watch: {
    data_top(val) {
        // if(val === '2'){
        //   this.get_guize()
        // }
    }
   //
  },
  data(){
    return {

    // myalistdata:["CP1616590570687a87h", "CP1616590606936bppr", "CP1616695300094gy3v"],
      options_value_box: [],
       treetest:true,
      //  propsss: {
      //     label: 'cat_name',
      //     children: 'list',
      //     isLeaf: 'leaf'
      //   },
      //   treedata:[],
      options_caipin:[],
      is_mendian_or_caipin: 0,
      options_value: [],
      index_mun: null,
      index2_mun: null,
      data_list_dianpu: [
         {shop_name: 'ssss'},
         {shop_name: 'ssss1'}
      ],
      data_list: [
        {
          title: '1方案',
          mendian: [],
          datacaipin: []
        }
      ],
      data_table: [
        {
          x:0,
          y:0,
          z:0
        },
        {
          x:1,
          y:2,
          z:3
        },
        {
          x:4,
          y:5,
          z:6
        }
      ],
      data_top: '1',
      market_type: {
        sum:'1',
        label:'早市'
      },
      options: [],
      activeNames: ['1'],
      value: '',
      data_cai_list:[],
      data_side: data_side,
      msg: null,
      data: [{
          id: 1,
          label: '一级 1',
          children: [{
            id: 4,
            label: '二级 1-1',
            children: [{
              id: 9,
              label: '三级 1-1-1'
            }, {
              id: 10,
              label: '三级 1-1-2'
            }]
          }]
        }, {
          id: 2,
          label: '一级 2',
          children: [{
            id: 5,
            label: '二级 2-1'
          }, {
            id: 6,
            label: '二级 2-2'
          }]
        }, {
          id: 3,
          label: '一级 3',
          children: [{
            id: 7,
            label: '二级 3-1'
          }, {
            id: 8,
            label: '二级 3-2'
          }]
        }],
      defaultProps: {
        children: 'children',
        label: 'label'
      },
      //data_Dian
      data_Dian: ['产品','sss','ssss','ssss'],
      //早市
      data_zs: {
        data_cai_value: [],
        data_list: []
      },
      //午市
      data_zws: {
        data_cai_value: [],
        data_list: []
      },
      // 晚市
      data_ws: {
        data_cai_value: [],
        data_list: []
      },
      // 外卖
      data_wm: {
        data_cai_value: [],
        data_list: []
      }
    }
  },
  mounted() {
    this.query_as_market_prod_list().then(res=>{
       let data = res.map(x=>{
                return {
                  prod_id:x.prod_id,
                  prod_name:x.prod_name,
                  prod_pic:x.prod_pic?.split(' ')[0]
                }
           })
           this.$nextTick(()=>{
              this.$set(this.datas,'data_list',data)
           })
    })
  },
  computed: {
      datas:function() {
      // if( this.value === '') { return  this.msg = this.$message({type:'warning',showClose: true,message:'请先选择门店',duration:'0'})}
        let data
        let num = this.market_type.label
        num === '早市' && ( data = this.data_zs)
        num === '午市' && ( data = this.data_zws)
        num === '晚市' && ( data = this.data_ws)
        num === '外卖' && ( data = this.data_wm)
        return data

      },
      ent_id(){
        return this.$store.state.ent_id
      },
      user_id(){
        return this.$store.state.user_id
      },
      cat_list(){
        return this.$store.state.cat_list
      }

  },
  updated(){
    //

  },
  created() {
    this.get_cat_list()
    this.get_data_dian();
    this.get_cailist();
    this.query_as_shop_prod_list()
    // this.query_as_market_prod_list()
    //this.query_category_tree()
    // this.get_cai_fl();
  },
  methods: {

    // 选择改便事件
    handleCheckedCitiesChange(val) {
      console.log(val,'45456asdsadasadas')
      //     this.data_cai_list =val.map(x=>{
      //      return {
      //             prod_id:x.cat_id,
      //             prod_name:x.cat_name,
      //             prod_pic:x.prod_pic?.split(' ')[0]
      //           }
      // })

       this.datas.data_list =  this.data_cai_list.map( x =>{
          if(val.indexOf(x.prod_id) !== -1){
              return {
                prod_name: x.prod_name,
                prod_id: x.prod_id,
                prod_pic:x.prod_pic
              }
          }
        }).filter(Boolean)
    },
    //早午晚市划分
    message_options_value_left(val) {

    },
    // 店铺售卖规则
    message_options_value(val) {
      this.options_value = val
    },
    // 请选择框改变事件
    changeItem(val,index,options,item) {
        //  console.log(val,index,options,item);
         let alldata = this.data_list
          if( JSON.stringify(alldata).indexOf(val.shop_id + '') !== -1){
            this.$message.warning('请勿重复添加门店')
          // let i = this.options_value_box.indexOf(val)
            // this.options_value_box.splice(i,1)
            // this.data_list.splice()
          }else{
            item.mendian.push(val)
          this.options_value_box.push(val)

          }
        //  this.data_list.forEach((item,index,array)=>{
        //   let arrList = item.mendian.map((ite,inde,arr)=>{
        //     return
        //    })
        //  })
    },
    del_mendian(data,item,indexx) {
       data.splice(indexx,1)
        // console.log(item,this.options_value_box);

      let index =   this.options_value_box.findIndex(x=>{
         return  x.shop_id === item.shop_id
        })
      this.options_value_box.splice(index,1)

    },
    loadNode(node, resolve) {
        if (node.parent_id === '0') {
          return resolve([{ name: node.cat_name }]);
        }
        if (Number(node.parent_id) > 0) {return resolve(data)}
      },
    del_fangan(data,index,item){
      // console.log(item.mendian,'1321235888');
        let _this = this
      this.$confirm('确认删除?').then(res=>{
          data.splice(index,1)


        for (const i in item.mendian){
          for(const j in _this.options_value_box){
            if(item.mendian[i].shop_id ===  _this.options_value_box[j].shop_id){
                _this.options_value_box.splice(j,1)
            }
          }
        }
         console.log(item.mendian);
        console.log(_this.options_value_box);

          this.$message.success('删除成功')
      }).catch(res=>{})
    },
    push_caipin(valx,item) {
       valx.push( ...this.options_value )
      this.index_mun = null
    },
    query_as_market_prod_list(item) {
           item = item || { label: "早市", sum: "1" }
      try {
          this.market_type = item
          let  market_type = item.sum
          const param1 = {
              ent_id: this.ent_id,
              market_type
            }
        console.warn(param1);
     return new Promise((resolve,reject)=>{
          query_as_market_prod_list({data:param1}).then(res=>{
         if(res.code === '200'){
           console.log(res,'5465----------------------------855');
           resolve(res.body.data)
            // let  datas = []
           this.datas.data_cai_value = res.body.data.map(x=>{
              return  x.prod_id
           }).filter((item,index,arr)=>{
              return arr.indexOf(item,0) === index
           })
           // 这里赋值不上去可以赋值到 zs data 上边改成proise
          this.datas.data_list = res.body.data.map(x=>{
                return {
                  prod_id:x.prod_id,
                  prod_name:x.prod_name,
                  prod_pic:x.prod_pic?.split(' ')[0]
                }
           })
           console.log(res.body.data,'6+415641++');
            //  this.$forceUpdate()
            // this.datas = datas
         }
       })
     })


      } catch(e){
        console.log(e);
      }

    },
       // 查询售卖规则
    query_as_shop_prod_list() {
      const param = {
        ent_id:this.$store.state.ent_id,
      }
      try {
        query_as_shop_prod_list({data:param}).then(res=>{
        if(res.code === '200'){
          console.log();
          let  data_list =[]
          if(res.body.data.length !== 0){
            data_list = JSON.parse(res.body.data[0].list_str)
          }
           for(let i=0; i< data_list.length;i++){
                data_list[i].datacaipin = data_list[i].datacaipin.split(',')
            }
          this.data_list = data_list
          }
          let list = []
          this.data_list.forEach(item => {
            list = [...list,...item.mendian]
          })
        this.options_value_box = list
      })

      } catch(e){
        console.log(e);
      }

    },
    //数组去重
    unique(arr){
      for (let i=0;i<arr.length;i++){
        for( let j=i+1;j<arr.length;j++){
          if(arr[i] == arr[j]){
            arr.splice(j,1);
            j--;
          }
        }
      }
    },
    // 划分菜品列表保存
    save_canpin() {
      const param = {
        ent_id: this.ent_id,
        // shop_id:  shop.shop_id,
        // shop_name: shop.shop_name,
        user_id: this.user_id,
        market_type: this.market_type.sum,
        prod_id: this.datas.data_list.map(x=>x.prod_id).join(',') // 产品id 多个逗号分割
      }
       console.log(param,this.datas);
      insert_as_market_prod({data:param}).then(res=>{
          if(res.code === '200'){
            this.$message.success('保存成功')
          }
      })
    },
    //添加方案
    add_fangan(){
      let title = '0'
      try {
          title = this.data_list[this.data_list.length-1].title

      } catch(e){
        console.log(e);
      }
        let num = parseInt(title)
       this.data_list.push( {
          title: `${num+1}方案`,
          mendian: [],
          datacaipin: []
        })
        function reverseString(str) {
            return str.split("").reverse().join("");
        }
    },
    add(val,index,num){
      this.index_mun = index
      this.is_mendian_or_caipin = num
    },
    add2(val,index){
        this.index2_mun = index
    },
    // 获取店铺售卖规则
    get_guize() {
      const param = {
            ent_id :this.ent_id
      }
      query_as_prod_rule({data:param}).then(res=>{
        // console.log(res,'--44444')
      })
    },

    // 更改门店 获取门店菜品名单
    del_left_list(index,id) {
      this.datas.data_list.splice(index,1)
     this.datas.data_cai_value =  this.datas.data_cai_value.filter(x=>x !== id)
    },
    // // 获取菜品列表
    get_cailist() {
      const param = {
        ent_id: this.ent_id,
        active: '1'
      }
      query_prod_info_list({data:param}).then(res=>{
       if(res.code === '10139'){
          this.data_cai_list= res.body.data.map(x=>{   //这个借口length 有问题
          return {
            prod_id: x.prod_id,
            prod_name: x.prod_name,
            prod_pic:x.prod_pic?.split(' ')[0] }
        })
       }

      })
    },
    //获取门店信息
    get_data_dian() {
      const param = {
            ent_id: this.ent_id
        }
       query_shop_info_list({data:param}).then(res=>{
         if(res.code === '200'){
           this.options = res.body.data.map(x=>{
             return {
               shop_name:x.shop_name,
               shop_id:x.shop_id
             }
           })
         }

       })

    },

    // 规则保存
    submit(){
       let data = JSON.parse (JSON.stringify(this.data_list))
      let param = {
        ent_id:this.$store.state.ent_id,
        list:[...data]
      }
      // console.log(param.list,'-4646');
      param.list.forEach(item=>{
       item.datacaipin = item.datacaipin.join(',')
      })
        let message = []
       param.list.forEach((item)=>{
         if( item.mendian.length <= 0){
           message.push(item.title)
         }
         if(item.datacaipin === ''){
           message.push(item.title)
         }
       })
       if(message.length > 0){
         message = [ ...new Set(message)]
         return this.$message.warning(`${message.join(',')},无法保存`)
       }
       console.log(param);
      // console.log(param,'这是param....');
      insert_as_shop_prod_rule({data:param}).then(res=>{
        console.log(res,'5995');
        if(res.code === '200'){
          this.$message.success('保存成功')
        }else{
          this.$message.error('保存失败')
        }
      })
    },

    ...mapActions(['get_cat_list']),
  }
}
</script>

<style scoped lang="scss">
  .title_ti{
    font-size: 17px;
    color: red;
    margin-left: 34px;
  }
  .mubiao{
   padding: 10px;
   box-sizing: border-box;
   display: flex;
   height: 100%;
   flex-flow: column nowrap;

    >.top{
      padding-left: 20px;
      height: 60px;
      min-height:60px;
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 20px;

    }
    >.main {
      margin-top: 20px;
      flex: 1;
      background: #fff;
      display: flex;

      >.left{
        width: 200px;
        background:rgb(238, 249, 255) ;
        >ul{
          >li{
            height: 60px;
            line-height: 60px;
            text-align: center;
            p{
              font-size: 14px;
            }
            &.active{
              background: #fff;
            }
          }
        }
      }
      >.right{
          width: 100%;
        >.title{
          height: 60px;
          text-align: right;
          line-height: 100px;
          padding-right: 20px;
        }
        >.content{
          display: flex;
          height:calc(100% - 110px);
          justify-content: space-around;
          padding: 30px;
          box-sizing: border-box;
          >.right{
            width: 49%;
            height: 100%;
          }
          >.left{
            width:49%;
              ul{
                height: 50px;
                border-radius: 6px;
                &.active{
                  background: #f2f6fc;
                }
                &:hover{
                  background: #eef9ff ;
                }
                >li{
                  height: 100%;
                  line-height: 50px;
                  padding-left: 20px;
                  padding-right: 20px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  >p{
                    width: 400px;
                    font-size: 14px;
                    overflow:hidden;
                    white-space:nowrap;
                    text-overflow:ellipsis;
                  }
                  >.img{
                    width:36px;
                    overflow: hidden;
                    height: auto;
                    line-height: 36px;
                    >img{
                      width: 100%;
                      height: auto;
                      display: inherit;
                    }
                  }
                }
              }

          }

        }
      }

    }
    //第二列
    >.main.two{
        position: relative;
        display: flex;
        flex-flow: row wrap;
      >.title{
           width: 100%;
            display: flex;
            height: 60px;
            justify-content:space-between;
          >.right{
            height: 60px;
            width: 260px;
            font-size: 14px;
            line-height: 120px;
            padding-left:40px;
            display: inline-flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            >span{
              width: 12px;
              height: 12px;
              display: inline-block;
              border-radius: 14px;
              &.red{
                background: red;
              }
              &.green{
                background: green;
              }
            }
          }
          >.left{
             height: 60px;
            text-align: right;
            line-height: 100px;
            padding-right: 20px;
          }
        }
        >.save{
          position: absolute;
          top: 20px;
          right: 30px;
        }
      >.box{
        &.box3{
          height: 30%;
          align-self: flex-start;
          display: flex;
          max-height: 300px;
          justify-content: center;
          align-items: center;
        }
        >.box3{
          display: flex;
          width: 90px;
          align-items: center;
          justify-content: space-between;
          font-size: 20px;
        }
        width: 300px;
        height: 600px;
        margin-left:60px;
        margin-top: 30px;
        border-radius: 6px;
        box-shadow: 4px 10px 14px #d4d4d4;
        >.title{
          position: relative;
          width: 100%;
          height: 50px;
          background: rgb(255, 55, 55);
          border-radius: 6px;
          border-bottom-right-radius: 0px;
          border-bottom-left-radius: 0px;
          font-size: 18px;
          color: #fff;
          text-align: center;
          line-height: 50px;
          .c-icon{
            position: absolute;
            right: 0px;
            fill: #fff;
            top: 0px;
          }
        }
        >.box1{
          width: 100%;
          position: relative;
          height:calc(50% - 30px);
          box-sizing: border-box;
          font-size: 14px;
           >.sidbar{
              position: absolute;
              border-radius: 6px;
              width: 260px;
              height: 200%;
              background: #fff;
              box-shadow: 4px 10px 14px #c5c5c5;
              top: 0;
              right: -280px;
              font-size: 20px;
              z-index: 2;
              display: none;
              >.main{
                height: 100%;
                padding: 20px;
                .mybox{
                  display: flex;
                  flex-flow: column nowrap;
                  font-size: 14px;
                  >li{
                    padding-left: 20px;
                    text-align: left;
                    line-height: 22px;
                    color: #8a8989;
                    display: inline-flex;
                     .c-icon{
                        display: none;
                     }
                     //修改状态 请选择
                   &.end_active{
                     .c-icon{
                       display: inline-flex;
                      position: absolute;
                      right:20px;
                      fill:red;
                    }
                   }
                  }


                }
              .tijiao{
                position: absolute;
                text-align: center;
                left: 50%;
                margin-left: -36px;
                bottom: 20px;

              }
              }

              &.active{
                display: block;
              }
              >.title{
                width: 100%;
                color: #fff;
                text-align: center;
                font-size: 16px;
                line-height: 30px;
                background: rgb(255, 55, 55);
                height: 30px;
              }
            }
          &.box2{
            height: 50%;
            border-top: 1px solid #ccc;
          }
          >ul{
              padding: 20px;
              padding-bottom: 10px;
              >.div-button{
                margin-top: 20px;
                text-align: center;
              }
            li{
              display: flex;
              align-items: center;
              padding-right: 10px;
              padding-left: 10px;
              line-height: 30px;
              // justify-content: space-between;
              >span{
                width: 10px;
                height: 10px;
                background: #b1b1b1;
                border-radius: 10px;
              }
              >p{
                // width: 100px;
                color: #8a8989;
                flex: 1;
                &:last-child{
                  width: 28px;
                  color: rgb(255, 89, 89);

                }
              }
            }
          }
        }
      }
    }

  }
 ::v-deep .el-scrollbar__wrap {
  overflow: scroll;
  border: none;
  height: calc(100% + 17px);
}
</style>
